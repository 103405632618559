import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import CustomEncoder from '../../core/helpers/custom-encoder';

@Injectable()
export class DisclosureService {
  url: string = environment.url;

  constructor(
    private http: HttpClient
  ) { }

  getVacancyDashboardSource(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/disclosures/vacancy-candidate-sources`, { params: httpParams });
  }

  getVacancyDashboardGeneralData(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/disclosures/vacancy-candidate-general-data`, { params: httpParams });
  }

  getVacancyDashboardProcessData(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/disclosures/vacancy-candidate-process-data`, { params: httpParams });
  }

  getVacancyDashboardTrailOfTheVacancy(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    // tslint:disable-next-line: max-line-length
    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/disclosures/vacancy-candidate-trail-of-the-vacancy`, { params: httpParams });
  }
}

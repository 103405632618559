import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { StorageService } from '../services';
import CONSTANTS from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ProtectSmartManagerGuard implements CanActivate {

  constructor(
    private storage: StorageService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.storage.getUser();

    if (user.role !== 'SMART_MANAGER') {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ObservableService {
    public processSource = new BehaviorSubject<any>(null);
    process = this.processSource.asObservable();

    constructor() {}

    setProcess(proc) {
        this.processSource.next(proc);
    }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IdealProfileModule } from '../../vacancies/ideal-profile/ideal-profile.module';

@Injectable()
export class IdealProfileService {
  changeCriteriaSubject: Subject<string> = new Subject<string>();
  isCriteriaPointsEmpty: Subject<object> = new Subject<object>();

  triggerChangeCriteriaSubject(nextCriteriaName: string): void {
    this.changeCriteriaSubject.next(nextCriteriaName);
  }

  getChangeCriteriaObservable() {
    return this.changeCriteriaSubject;
  }

  triggerCriteriaPointsSubject(object: { isEmpty: boolean, nextCriteriaName: string }) {
    this.isCriteriaPointsEmpty.next(object);
  }

  getCriteriaPointsObservable() {
    return this.isCriteriaPointsEmpty;
  }

}
